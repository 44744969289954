import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Paper } from "@material-ui/core"

import { Dialog, ModalContent, ModalHeader } from "app/components/modals"
import NewPatientModal from "app/components/modals/NewPatientModal"
import useAppSelector from "app/hooks/useAppSelector"
import { StartNewOrder } from "app/main/dashboard/StartNewOrder"
import { getPatients } from "app/main/dashboard/store/actions"
import { selectPractitioner } from "app/store/selectors/practitioner.selectors"
import { Patient } from "app/types"
import { getOrderPatientLabel } from "app/utils/order-utils"

interface Props {
  open: boolean
  onClose: () => void
  shouldHideCreatePatientButton?: boolean
  onSelectPatient?: (patient: Patient) => void
  // place in the app where the click came from, for tracking purposes
  origin?: string
}

const StartOrderModal: React.FC<Props> = ({
  open,
  onClose,
  shouldHideCreatePatientButton = false,
  onSelectPatient,
  origin,
}) => {
  const dispatch = useDispatch()
  const practitioner = useAppSelector(selectPractitioner)

  const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false)
  const [newPatientModalName, setNewPatientModalName] = useState("")

  // During the product tour, Hide the existing list of patients for that clinic, So
  // that the staff/practitioner will not select an existing patient. (Selecting an
  // existing patient will prevent the tour from happening.)
  const patients = useAppSelector(({ dashboard }) =>
    dashboard ? dashboard.dashboard.patients : []
  )

  useEffect(() => {
    // Get patients if we don't have any fetched
    if (patients.length === 0) {
      dispatch(getPatients())
    }
  }, [])

  const handleOpenNewPatientModal = useCallback(
    ({ searchText }) => {
      setNewPatientModalName(searchText)
      setIsNewPatientModalOpen(true)
    },
    [setNewPatientModalName, setIsNewPatientModalOpen]
  )

  // TODO: Loading State
  if (!practitioner) {
    return null
  }

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby={`start-order-modal-title`}
        open={open}
        maxWidth="md"
        PaperProps={{
          style: {
            margin: 15,
            borderRadius: 7,
          },
        }}
      >
        <ModalHeader
          onClose={onClose}
          id="start-order-modal-title"
          title={`Choose or Create a ${getOrderPatientLabel(
            practitioner.default_physician_authorization
          )}`}
        />
        <ModalContent className="sm:p-8 bg-slate-100 border-y border-slate-300">
          <Paper className="flex flex-col justify-center bg-white min-h-[85px] min-w-[80%] sm:min-w-[405px] ">
            <StartNewOrder
              patients={patients}
              handleOpenNewPatientModal={handleOpenNewPatientModal}
              onSelectPatient={onSelectPatient}
              defaultPhysicianServices={
                practitioner.default_physician_authorization
              }
            />
          </Paper>
        </ModalContent>
      </Dialog>
      <NewPatientModal
        open={isNewPatientModalOpen}
        messages={{
          patientTerm: getOrderPatientLabel(
            practitioner.default_physician_authorization
          ),
        }}
        onClose={(closeStartOrder) => {
          setIsNewPatientModalOpen(false)

          if (closeStartOrder) {
            onClose()
          }
        }}
        name={newPatientModalName}
        shouldHideCreatePatientButton={shouldHideCreatePatientButton}
        practitioner={practitioner}
        origin={origin}
      />
    </>
  )
}

export default StartOrderModal
