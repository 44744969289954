import { KIT_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { getPhlebotomistFriendlyName } from "../../utils"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({ kit, order, patient }: StatusConfigurationProps) => {
  const appointmentDate = kit.attributes.date_sample_collection_scheduled
  if (!appointmentDate) return null

  const dateIsInThePast = new Date(appointmentDate) < new Date()

  const formattedDate = formatDateForHuman(appointmentDate)
  const patientName = patient?.attributes.first_name
  const phlebotomyProviderText =
    order.attributes.phlebotomy_providers.length === 1
      ? ` with ${getPhlebotomistFriendlyName(
          order.attributes.phlebotomy_providers[0]
        )}`
      : ""

  if (patientName)
    return `${patientName} ${
      dateIsInThePast
        ? "planned to collect the sample on"
        : "plans to collect the sample on"
    } ${formattedDate}${phlebotomyProviderText}.`

  return `The patient's sample collection appointment ${
    dateIsInThePast ? "was scheduled on" : "is scheduled for"
  } ${formattedDate}${phlebotomyProviderText}.`
}

const collectionAppointmentScheduledStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { kitStatus: KIT_STATUS.COLLECTION_SCHEDULED },
    title: "Collection Appointment Scheduled",
    subTitle: SubTitle,
    progressPercentage: PROGRESS_PERCENTAGES.COLLECTION_SCHEDULED,
  }

export default collectionAppointmentScheduledStatusConfiguration
