// This is the new ORDER_STATUS constants to match the backend.
export const ORDER_STATUS = {
  // Standard flow
  DRAFT: "Draft",
  PENDING_PAYMENT: "Pending Payment",
  PENDING_SHIPPING_INFO: "Pending Shipping Info",
  AWAITING_SHIPMENT: "Awaiting Shipment",
  IN_PROGRESS: "In Progress",
  COMPLETE: "Complete",
  RESULTS_REVIEWED: "Results Reviewed",

  // Special Cases
  PAUSED: "Paused",
  SCHEDULED: "Scheduled",
  AWAITING_APPROVAL: "Awaiting Approval",
  CANCELED: "Canceled",
  BLOCKED_MISSING_SIGNATURE: "Blocked: Missing Signature",
  BLOCKED_PRACTITIONER_ACCOUNT_UNVERIFIED:
    "Blocked: Practitioner Account Unverified",
  DEMO: "Demo Order",
  PRELIMINARY_RESULTS_IN: "Preliminary Results In",

  // Vendor Physician Authorization
  // This constant does not use VENDOR_PHYSICIAN_AUTHORIZATION_LABEL because
  // in this case "physician authorization" is a discrete step of the order
  // lifecycle.
  PENDING_PHYSICIAN_AUTHORIZATION: "Pending Physician Authorization",
  PENDING_LABSHOP_PAYMENT: "Pending LabShop Payment",
  DENIED_BY_LABSHOP: "Denied By LabShop",
  PENDING_LABSHOP_APPROVAL: "Pending LabShop Approval",
} as const

export type OrderStatusUnion = typeof ORDER_STATUS[keyof typeof ORDER_STATUS]

export const IOK_ORDER_STATUS = {
  DRAFT: "Draft",
  PENDING_LAB_ACCOUNT: "Pending Lab Account",
  ORDER_RECEIVED: "Order Received",
  PENDING_SHIPMENT: "Pending Shipment",
  SHIPPED: "Shipped",
}

export type IOKOrderStatusUnion =
  typeof IOK_ORDER_STATUS[keyof typeof IOK_ORDER_STATUS]

export const ORDERED_TEST_STATUS = {
  CANCELED: "Canceled",
  SUBMITTED: "Submitted",
  PAID: "Paid",
  ORDERED_WITH_LAB: "Ordered With Lab",
  COLLECTION_SCHEDULED: "Collection Scheduled",
  COLLECTION_COMPLETED: "Collection Completed",
  AT_LAB: "At Lab",
  ACTION_REQUIRED_SPECIMEN_ISSUE: "Specimen Issue",
  RECOLLECTION_IN_PROGRESS: "Sample Recollection In Progress",
  PROCESSING_SPECIMEN_ISSUE_ACTION: "Processing Specimen Issue Action",
  PENDING_DETAILS_SPECIMEN_ISSUE: "New Specimen Issue",
  PRELIMINARY_RESULTS_AVAILABLE: "Preliminary Results Available",
  COMPLETE: "Complete",

  // Vendor Physician Authorization
  // These constants do not use VENDOR_PHYSICIAN_AUTHORIZATION_LABEL because
  // in this case "physician authorization" is a discrete step of the order
  // lifecycle.
  PENDING_PHYSICIAN_AUTHORIZATION: "Pending Physician Authorization",
  PHYSICIAN_AUTHORIZATION_DENIED: "Physician Authorization Denied",
} as const

export type OrderedTestStatusUnion =
  typeof ORDERED_TEST_STATUS[keyof typeof ORDERED_TEST_STATUS]

export type SampleCollectionCoordinator =
  | "CLINIC"
  | "LAB"
  | "PATIENT"
  | "UNKNOWN"

export const IN_OFFICE_KIT_STATUS = {
  IN_STOCK: "in_stock",
  ACTIVATED: "activated",
  FAILED: "failed",
  PRE_STOCK: "pre_stock",
}

export type InOfficeKitStatusUnion =
  typeof IN_OFFICE_KIT_STATUS[keyof typeof IN_OFFICE_KIT_STATUS]

export const KIT_GROUP_TYPE = {
  PANEL: "panel",
  PARENT: "parent",
  INDIVIDUAL: "individual",
} as const

export type KitGroupTypeUnion =
  typeof KIT_GROUP_TYPE[keyof typeof KIT_GROUP_TYPE]

export const ORDER_PAYER = {
  PRACTITIONER: "practitioner",
  PATIENT: "patient",
} as const

export type OrderPayerUnion = typeof ORDER_PAYER[keyof typeof ORDER_PAYER]

export const LAB_TEST_TYPE_KEY = {
  BIOPSY: "Biopsy",
  BLOOD: "Blood",
  BLOOD_NANOTAINER: "blood Nanotainer",
  BLOOD_SPOT: "Blood Spot",
  BREATH: "Breath",
  CEREBRAL_SPINAL_FLUID: "Cerebral Spinal Fluid",
  FINGER_STICK: "Finger Stick",
  HAIR: "Hair",
  MULTIPLE: "Multiple",
  PLASMA: "Plasma",
  SALIVA: "Saliva",
  SERUM: "Serum",
  STOOL: "Stool",
  SWAB: "Swab",
  URINE: "Urine",
  WATER: "Water",
  WHOLE_BLOOD: "Whole Blood",
  RED_BLOOD_CELLS: "Red Blood Cells",
} as const

export type LabTestTypeKeyUnion =
  typeof LAB_TEST_TYPE_KEY[keyof typeof LAB_TEST_TYPE_KEY]

export enum StorefrontStatus {
  Disabled = "DISABLED",
  Published = "PUBLISHED",
  Paused = "PAUSED",
  Archived = "ARCHIVED",
}

export enum PractitionerProfilePublishingStatus {
  DRAFT = "draft",
  PENDING_APPLICATION = "pending_application",
  DIRTY = "dirty",
  PUBLISHED = "published",
  PAUSED = "paused",
}

export enum PractitionerProfileApplicationStatus {
  PENDING = "pending",
  APPROVED = "approved",
  DENIED = "denied",
}

/**
 * The statuses of a Kit. Mostly in line with OrderedTest statuses.
 */
export const KIT_STATUS = {
  CANCELED: "Canceled",
  SUBMITTED: "Submitted",
  PAID: "Paid",
  ORDERED_WITH_LAB: "Ordered With Lab",
  COLLECTION_SCHEDULED: "Collection Scheduled",
  COLLECTION_COMPLETED: "Collection Completed",
  AT_LAB: "At Lab",
  ACTION_REQUIRED_SPECIMEN_ISSUE: "Specimen Issue",
  RECOLLECTION_IN_PROGRESS: "Sample Recollection In Progress",
  PROCESSING_SPECIMEN_ISSUE_ACTION: "Processing Specimen Issue Action",
  PENDING_DETAILS_SPECIMEN_ISSUE: "New Specimen Issue",
  PRELIMINARY_RESULTS_AVAILABLE: "Preliminary Results Available",
  RESULTS_IN: "Results In",
  COMPLETE: "Complete",

  // Vendor Physician Authorization
  // These constants do not use VENDOR_PHYSICIAN_AUTHORIZATION_LABEL because
  // in this case "physician authorization" is a discrete step of the order
  // lifecycle.
  PENDING_PHYSICIAN_AUTHORIZATION: "Pending Physician Authorization",
  PHYSICIAN_AUTHORIZATION_DENIED: "Physician Authorization Denied",
} as const

export type KitStatusUnion = typeof KIT_STATUS[keyof typeof KIT_STATUS]

export const SPECIMEN_ISSUE_STATUS = {
  PENDING_DETAILS: "Pending Details",
  ACTION_REQUIRED: "Action Required",
  RECOLLECTION_IN_PROGRESS: "Recollection in Progress",
  PROCESSING_ACTION: "Processing Action",
  RESOLVED: "Resolved",
}

export type SpecimenIssueStatusUnion =
  typeof SPECIMEN_ISSUE_STATUS[keyof typeof SPECIMEN_ISSUE_STATUS]

export const SPECIMEN_ISSUE_ACTION = {
  SHIP_RECOLLECTION_FULL: "ship_recollection_full",
  SHIP_RECOLLECTION_PARTIAL: "ship_recollection_partial",
  REDRAW_IN_OFFICE_KIT: "redraw_in_office_kit",
  ACCEPT_PARTIAL_RESULT: "accept_partial_result",
  CONVERT_TESTS: "convert_tests",
  CANCEL_AND_REFUND: "cancel_and_refund_tests",
  CONTINUE_TEST_PROCESSING: "continue_test_processing",
  RECOLLECT_AT_LABCORP: "recollect_at_labcorp",
}

export type SpecimenIssueActionUnion =
  typeof SPECIMEN_ISSUE_ACTION[keyof typeof SPECIMEN_ISSUE_ACTION]
