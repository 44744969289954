import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { saveAs } from "file-saver"
import { isEmpty } from "lodash"
import { useParams } from "react-router-dom"
import { useSearchParam } from "react-use"

import BodyText from "app/components/design-system/BodyText"
import DisplayText from "app/components/design-system/DisplayText"
import Error404Page from "app/main/errors/404/Error404Page"
import PatientInstruction from "app/main/patient-portal/PatientInstruction"
import Card from "app/patient-portal/Card"
import { PatientPortalPayment } from "app/patient-portal/types"
import {
  PATIENT_PORTAL_TRACKING_EVENTS,
  trackPatientPortalEvent,
} from "app/services/segment"
import { showMessage } from "app/store/actions"
import useCachedResource from "app/swr/hooks/use-cached-resource"

import { usePatientPortalUserOrderResource } from "../hooks/use-patient-portal-user-order-resource"
import PatientPortalOrderKitList from "./PatientPortalOrderKitList"
import PatientPortalOrderLoadingState from "./PatientPortalOrderLoadingState"

const PatientPortalOrder = () => {
  const dispatch = useDispatch()

  const { orderId, userId } = useParams<{
    orderId: string
    userId: string
  }>()
  const expandedIndexParam = useSearchParam("expanded-index")
  const [expandedIndex, setExpandedIndex] = useState(
    parseInt(expandedIndexParam || "0", 10) || 0
  )

  // these params indicate that we should automatically download the receipt or superbill
  const shouldDownloadReceipt = useSearchParam("download-receipt")
  const shouldDownloadSuperbill = useSearchParam("download-superbill")
  const orderPaymentId = useSearchParam("order-payment")

  const payment = useCachedResource<PatientPortalPayment>(
    orderPaymentId
      ? {
          type: "patient_portal_payment",
          id: orderPaymentId,
        }
      : undefined
  )

  useEffect(() => {
    trackPatientPortalEvent(PATIENT_PORTAL_TRACKING_EVENTS.ORDER_PAGE_VIEWED, {
      order_id: orderId,
    })
  }, [])

  useEffect(() => {
    if (shouldDownloadSuperbill === "true") {
      dispatch(
        showMessage({
          message: "Superbill downloads are no longer available.",
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      )
      removeDownloadParam()
    }
  }, [shouldDownloadSuperbill, payment])

  useEffect(() => {
    if (shouldDownloadReceipt === "true" && payment?.attributes.receipt_url) {
      downloadReceipt("Receipt", payment.attributes.receipt_url)
    }
  }, [shouldDownloadReceipt, payment])

  const downloadReceipt = (documentType: "Receipt", url: string) => {
    dispatch(
      showMessage({
        message: `${documentType} downloading...`,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    )
    // we have to fetch the file data and then save it because
    // there is a bug with filesaver and AWS HEAD requests if we try
    // to directly feed the url to saveAs
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, `rupa-${documentType.toLowerCase()}.pdf`)
        dispatch(
          showMessage({
            message: `${documentType} downloaded! Check your downloads folder.`,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          })
        )
      })
    removeDownloadParam()
  }

  const removeDownloadParam = () => {
    // remove only the download param so that we don't download it again
    // if the user refreshes the page
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname +
        window.location.search
          .replace(/&?download-receipt=true/, "")
          .replace(/&?download-superbill=true/, "")
    )
  }

  const { error, ready, order, practitioner, patient } =
    usePatientPortalUserOrderResource(orderId, userId)

  useEffect(() => {
    if (ready && order?.attributes.patient_checkout_url) {
      window.location.href = order.attributes.patient_checkout_url
    }
  }, [ready, order?.attributes.patient_checkout_url])

  if (error) {
    // If we have errors fetching the order, we show a 404 page.
    // This could be due to a server error, or someone trying to access an incorrect order
    return <Error404Page />
  }

  if (!ready || !order?.attributes?.date_patient_checkout) {
    return (
      <div className="flex flex-col gap-3 sm:gap-6">
        <PatientPortalOrderLoadingState />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3 sm:gap-6">
      <PatientPortalOrderKitList order={order} />

      {!isEmpty(order.attributes.patient_instructions) && (
        <Card className={"flex flex-col gap-4"}>
          <DisplayText size="lg" weight="semibold">
            Prepare for Your Sample Collection
          </DisplayText>

          {Boolean(order.attributes.notes_to_patient) && (
            <div className={"flex flex-col gap-1"}>
              <BodyText weight="semibold">
                Note from {practitioner.attributes.titled_full_name}:
              </BodyText>
              <BodyText italic>{order.attributes.notes_to_patient}</BodyText>
            </div>
          )}
          {order.attributes.patient_instructions.map(
            (patientInstruction, index) => (
              <PatientInstruction
                key={patientInstruction.type}
                patientInstruction={patientInstruction}
                expanded={expandedIndex === index}
                onExpand={() =>
                  setExpandedIndex(expandedIndex === index ? -1 : index)
                }
                patient={patient}
              />
            )
          )}
        </Card>
      )}
    </div>
  )
}

export default PatientPortalOrder
