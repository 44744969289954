import React from "react"

import { ReactComponent as ReceiptIcon } from "app/assets/icons/receipt.svg"
import { PatientPortalPayment } from "app/patient-portal/types"

export interface OrderPaymentDisplayType {
  type: "receipt"
  attributes?: PatientPortalPayment["attributes"]
  url?: string | null
  disabled?: boolean
  tooltipTitle?: React.ReactNode
  tooltipSubtitle?: React.ReactNode
}

export interface ReceiptType extends OrderPaymentDisplayType {
  type: "receipt"
}

export const getButtonDisplayInfo = (
  paymentType: OrderPaymentDisplayType["type"]
) => {
  switch (paymentType) {
    case "receipt":
      return { Icon: ReceiptIcon, text: "Receipt" }
  }
}

export const getReceiptsToDisplay = (
  payments: PatientPortalPayment[]
): ReceiptType[] => {
  return payments.reduce((acc, payment) => {
    const receipt = getReceiptDisplayInfo(payment)
    if (receipt) {
      acc.push(receipt)
    }
    return acc
  }, [] as ReceiptType[])
}

const getReceiptDisplayInfo = (
  payment: PatientPortalPayment | undefined
): ReceiptType | undefined => {
  if (!payment?.attributes.receipt_url) {
    return undefined
  }

  return {
    type: "receipt",
    url: payment.attributes.receipt_url,
    attributes: payment.attributes,
  }
}
