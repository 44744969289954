import { useMemo } from "react"

import useQuery from "app/hooks/use-query"
import { trackPatientPortalAction } from "app/services/segment.typed"

import { OrderPaymentDisplayType } from "./helpers"

export default function useDownloadOrderPayment(
  payment: OrderPaymentDisplayType
) {
  const query = useQuery()

  const downloadOrderPayment = useMemo(() => {
    const url = payment.url
    if (url) {
      return () => {
        window.open(url, "_blank")
        trackPatientPortalAction(query.get("token"), "download_receipt")
      }
    }
    return undefined
  }, [query, payment])

  return downloadOrderPayment
}
