import { useState } from "react"

import axios from "axios"

import {
  faCalendarCheck,
  faCalendarLinesPen,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Input } from "@rupahealth/design"

import { PatientPortalPatient } from "app/patient-portal/types"
import {
  PatientInstructionCollectionDateEntry,
  OrderedTestCollectionDateEntry,
} from "app/types"
import { getNormalizedApiUrl } from "app/utils"
import formatDateForHuman from "app/utils/format-date-for-human"

const EnterCollectionDateButtonInput = ({
  orderedTestCollectionDateEntry,
  patient,
}: {
  orderedTestCollectionDateEntry: OrderedTestCollectionDateEntry
  patient: PatientPortalPatient
}) => {
  const [plannedCollectionDate, setPlannedCollectionDate] = useState(
    orderedTestCollectionDateEntry.planned_collection_date
  )
  const [confirmedCollectionDate, setConfirmedCollectionDate] = useState(
    orderedTestCollectionDateEntry.confirmed_collection_date
  )
  const collectionDateDatePart = plannedCollectionDate?.split("T")[0]
  const [isEditing, setIsEditing] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [plannedCollectionDateEntry, setPlannedCollectionDateEntry] = useState(
    collectionDateDatePart || ""
  )
  const [confirmedCollectionDateEntry, setConfirmedCollectionDateEntry] =
    useState(collectionDateDatePart || "")
  const [error, setError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isDateInThePast = (
    dateString: string,
    includeToday: boolean = false
  ) => {
    const selectedDate = new Date(dateString)
    const today = new Date()
    today.setHours(0, 0, 0, 0) // Ignore time
    return includeToday ? selectedDate <= today : selectedDate < today
  }

  const isValidDate = (dateString: string, confirming: boolean) => {
    const selectedDate = new Date(dateString)
    return (
      !isNaN(selectedDate.getTime()) &&
      (!confirming || isDateInThePast(dateString, true))
    )
  }

  const handleSubmit = async (date: string, confirming: boolean) => {
    if (isValidDate(date, confirming)) {
      setIsSubmitting(true)

      const submitConfirm = confirming || isDateInThePast(date)

      const updateAttributes = submitConfirm
        ? { date_sample_collection_completed: date }
        : { date_sample_collection_scheduled: date }

      try {
        await axios.post(
          getNormalizedApiUrl() +
            `/patient_portal/patient/${patient.id}/ordered_test/bulk_update/`,
          {
            ordered_test_ids: orderedTestCollectionDateEntry.ordered_test_ids,
            attributes: updateAttributes,
          }
        )

        setIsEditing(false)
        setIsConfirming(false)
        setError("")
        setSuccessMessage(
          submitConfirm
            ? `Thanks for confirming your collection!`
            : `Thanks for planning your collection for ${formatDateForHuman(
                date
              )}`
        )
        setTimeout(() => {
          if (submitConfirm) {
            setConfirmedCollectionDate(date)
          } else {
            setPlannedCollectionDate(date)
          }
          setSuccessMessage("")
        }, 5000)
      } catch (err) {
        setError("Failed to update collection date. Please try again.")
        console.error("Error updating collection date:", err)
      } finally {
        setIsSubmitting(false)
      }
    } else {
      setError(
        "Please enter a valid date!" + confirming
          ? " You can't confirm a collection date in the future."
          : ""
      )
    }
  }

  const handleCancel = () => {
    setIsEditing(false)
    setIsConfirming(false)
    setPlannedCollectionDateEntry(collectionDateDatePart || "")
    setConfirmedCollectionDateEntry(collectionDateDatePart || "")
    setError("")
  }

  const DateEntry = ({
    dateState,
    setDateState,
    confirming,
  }: {
    dateState: string
    setDateState: (date: string) => void
    confirming: boolean
  }) => {
    return (
      <div className="flex flex-col items-start space-y-2">
        <div className="flex flex-row items-center space-x-2">
          <div className="relative">
            <Input
              type="date"
              value={dateState}
              onChange={(e) => setDateState(e.target.value)}
              disabled={isSubmitting}
              max={
                confirming ? new Date().toISOString().split("T")[0] : undefined
              }
            />
          </div>
          <Button
            size="sm"
            onClick={() => handleSubmit(plannedCollectionDateEntry, confirming)}
            disabled={isSubmitting}
          >
            {confirming ? "Confirm" : "Save"}
          </Button>
          <button
            onClick={handleCancel}
            className="text-sm text-gray-500"
            disabled={isSubmitting}
          >
            Cancel
          </button>
        </div>
        {error && <p className="text-xs text-red-500">{error}</p>}
      </div>
    )
  }

  const PlannedCollectionDateView = ({
    plannedCollectionDate,
    setIsConfirming,
    setIsEditing,
  }: {
    plannedCollectionDate: string
    setIsConfirming: (isConfirming: boolean) => void
    setIsEditing: (isEditing: boolean) => void
  }) => {
    const dateIsInThePast = isDateInThePast(plannedCollectionDate)
    const formattedDate = formatDateForHuman(plannedCollectionDate)
    return (
      <div className="text-sm text-gray-500 flex flex-col gap-2">
        <p>
          Your sample collection {dateIsInThePast ? "was" : "is"} planned for{" "}
          {formattedDate}
        </p>
        <div className="flex flex-col md:flex-row gap-1 md:gap-3">
          <div className="flex flex-row gap-2 items-center">
            <button
              className="bg-success-600 text-white rounded-md flex items-center justify-start py-1 h-6 w-8 min-w-8 pl-2.5 pr-2.5"
              onClick={() => setIsConfirming(true)}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />
            </button>
            <p>Completed your collection?</p>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <button
              className="bg-primary text-white rounded-md flex items-center justify-start py-1 h-6 w-8 min-w-8 pl-2.5"
              onClick={() => setIsEditing(true)}
            >
              <FontAwesomeIcon icon={faCalendarLinesPen} />
            </button>
            <p>Rescheduled?</p>
          </div>
        </div>
      </div>
    )
  }

  if (successMessage) {
    return <p className="text-green-600">{successMessage}</p>
  }

  if (confirmedCollectionDate) {
    return (
      <div className="text-sm text-gray-500 flex flex-col gap-2">
        <p>
          You completed your collection on{" "}
          {formatDateForHuman(confirmedCollectionDate)}
        </p>
      </div>
    )
  }

  if (
    plannedCollectionDate &&
    !confirmedCollectionDate &&
    !isEditing &&
    !isConfirming
  ) {
    return (
      <PlannedCollectionDateView
        plannedCollectionDate={plannedCollectionDate}
        setIsConfirming={setIsConfirming}
        setIsEditing={setIsEditing}
      />
    )
  }

  if (isConfirming) {
    return (
      <div className="flex flex-col items-start space-y-2">
        <p>Confirm the date you completed your collection:</p>
        <DateEntry
          dateState={confirmedCollectionDateEntry}
          setDateState={setConfirmedCollectionDateEntry}
          confirming={true}
        />
      </div>
    )
  }

  if (isEditing) {
    return (
      <div className="flex flex-col items-start space-y-2">
        <p>Enter your planned collection date:</p>
        <DateEntry
          dateState={plannedCollectionDateEntry}
          setDateState={setPlannedCollectionDateEntry}
          confirming={false}
        />
      </div>
    )
  }

  return (
    <div className="flex flex-row items-center gap-4">
      <p>Know when you'll collect your sample?</p>
      <Button size="sm" onClick={() => setIsEditing(true)}>
        Enter Collection Date
      </Button>
    </div>
  )
}

const EnterCollectionDateInstructions = ({
  patientInstruction,
  patient,
}: {
  patientInstruction: PatientInstructionCollectionDateEntry
  patient: PatientPortalPatient
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {patientInstruction.ordered_test_collection_date_entries.map(
        (entry, index, array) => (
          <div key={entry.name}>
            <div className="flex flex-col items-start space-y-2">
              <p className="font-semibold text-base">{entry.name}</p>
              <EnterCollectionDateButtonInput
                orderedTestCollectionDateEntry={entry}
                patient={patient}
              />
            </div>
            {index < array.length - 1 && (
              <hr className="h-px bg-slate-200 mt-5" />
            )}
          </div>
        )
      )}
    </div>
  )
}

export default EnterCollectionDateInstructions
