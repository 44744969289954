// These values are arbitrary
export const PROGRESS_PERCENTAGES = {
  DRAFT: 0,
  PAUSED: 0,
  CANCELED: 0,
  AWAITING_PATIENT_CHECKOUT: 10,
  PAID: 20,
  PENDING_PHYSICIAN_AUTHORIZATION: 20,
  ORDERED_WITH_LAB: 35,
  COLLECTION_SCHEDULED: 40,
  COLLECTION_COMPLETED: 50,
  NEW_SPECIMEN_ISSUE: 60,
  PROCESSING_SPECIMEN_ISSUE_ACTION: 65,
  RECOLLECTION_IN_PROGRESS: 65,
  AT_LAB: 70,
  PRELIMINARY_RESULTS_AVAILABLE: 80,
  RESULTS_IN: 90,
  COMPLETE: 100,
} as const
