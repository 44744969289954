import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import axios from "axios"
import clsx from "clsx"
import _ from "lodash"

import { Paper, Popper, makeStyles, styled } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Typography from "@material-ui/core/Typography"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { API } from "app/api"
import MagnifyingGlassDark from "app/assets/icons/magnifying-glass-dark.svg"
import Recent from "app/assets/icons/recent.svg"
import Suggested from "app/assets/icons/suggested.svg"
import { ReactComponent as TrashCanIcon } from "app/assets/icons/trash-can.svg"
import BodyText from "app/components/design-system/BodyText"
import TextField from "app/components/forms/TextFieldComponent"
import { ModalContent, ModalHeader } from "app/components/modals"
import { colors } from "app/theme"
import { handleApiError } from "app/utils"

import useCheckoutDraft from "./hooks/use-checkout-draft"

let getICD10CodesCancelToken = null

const withStyles = makeStyles({
  input: {
    fontSize: 14,
    padding: "4px 12px !important",
    "&::placeholder": {
      opacity: 1,
    },
  },
})

const StyledPaper = styled(Paper)({
  "& .MuiAutocomplete-groupUl .MuiAutocomplete-option": {
    margin: "auto 15px",
    paddingLeft: 5,
    borderRadius: 5.5,
  },
  "& .MuiAutocomplete-option[data-focus='true']": {
    backgroundColor: colors.coolGray[100],
  },
  "& .MuiAutocomplete-listbox li:nth-child(2) .MuiAutocomplete-groupLabel": {
    borderTop: `1px solid ${colors.gray[200]}`,
    margin: "auto 16px",
    padding: 0,
    marginTop: 15,
  },
})

const StyledPopper = styled(Popper)({
  maxHeight: 100,
})

const AutocompleteCategoryIcon = styled("img")({
  marginRight: 15,
})

const StyledICD10Code = styled("span")({
  width: 75,
  display: "inline-block",
})

const FlexBox = styled("div")({
  display: "flex",
})

export function ICD10CodesModal({ open, onClose, title, subtitle = "" }) {
  const { updateValues, values } = useCheckoutDraft()
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      aria-labelledby="icd10-codes-modal-title"
      open={open}
      /* Needed for fixing an accessibility error raised by Axe */
      TransitionProps={{ role: "presentation" }}
    >
      <ModalHeader
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        ariaLabelId={"icd10-codes-modal-title"}
      />
      <ModalContent>
        <ICD10Autocomplete
          selectedCodes={values.icd10_codes_discrete}
          onChange={(newValue) =>
            updateValues({ icd10_codes_discrete: newValue })
          }
        />
      </ModalContent>
    </Dialog>
  )
}

function ICD10Autocomplete({ selectedCodes, onChange }) {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const styles = withStyles()

  const label = "Search for ICD-10 Codes"

  const fetch = useMemo(
    () =>
      _.debounce(async (search, callback) => {
        setLoading(true)

        if (getICD10CodesCancelToken) {
          getICD10CodesCancelToken.cancel()
        }

        getICD10CodesCancelToken = axios.CancelToken.source()

        try {
          const response = await API.ICD10Code.getList(
            search,
            getICD10CodesCancelToken && getICD10CodesCancelToken.token
          )
          setLoading(false)
          callback(response && response.data && response.data.results)
        } catch (error) {
          if (axios.isCancel(error)) {
            // If this network request was canceled due to a new one being started then do not setLoading(false)
            // as we want the loading indicator to stay for the new active request.
            return
          }

          setLoading(false)
          dispatch(handleApiError(error))
        }
      }, 500),
    []
  )

  useEffect(() => {
    setValue(selectedCodes)
  }, [selectedCodes])

  useEffect(() => {
    let active = true

    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results)
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  const updateValue = (newValue) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <div>
      <Autocomplete
        multiple
        autoHighlight
        fullWidth
        clearOnBlur={false}
        forcePopupIcon={false}
        loading={loading}
        value={value}
        groupBy={(option) => option.category}
        PopperComponent={AutocompletePopper}
        PaperComponent={AutocompletePaper}
        onChange={(event, newValue, reason) => {
          // Prevent pressing of the backspace button when no text is entered from deleting selected items.
          if (reason !== "remove-option") {
            // Prevent selecting the same item more than once.
            const uniqueSelections = _.uniqBy(newValue, (item) => item.id)
            updateValue(uniqueSelections)
          }
        }}
        options={options}
        filterOptions={(options, state) => options}
        renderOption={(option) => (
          <FlexBox>
            <AutocompleteCategoryIcon
              src={option.category === "Frequently Used" ? Recent : Suggested}
              alt="option"
            />
            {/* Highlight items that are already selected. */}
            <BodyText
              weight={
                _.some(value, (code) => code.id === option.id)
                  ? "semibold"
                  : "regular"
              }
            >
              <StyledICD10Code>{option.code}</StyledICD10Code>
              {option.short_description}
            </BodyText>
          </FlexBox>
        )}
        aria-label={label}
        label={label}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            multiline
            variant={"outlined"}
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" style={{ marginRight: 2 }}>
                  <img
                    src={MagnifyingGlassDark}
                    width={18}
                    height={18}
                    alt="Magnifying Glass"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              ),
              classes: {
                input: styles.input,
              },
            }}
            className="fs-unmask bg-white"
          />
        )}
      />
      {value &&
        value.map((selectedCode) => (
          <SelectedCode
            key={selectedCode.id}
            code={selectedCode.code}
            description={selectedCode.short_description}
            className="mt-3"
            onDelete={() =>
              updateValue(
                _.reject(value, (code) => code.id === selectedCode.id)
              )
            }
          />
        ))}
    </div>
  )
}

const AutocompletePopper = (props) => {
  return <StyledPopper {...props} placement="bottom" />
}

const AutocompletePaper = (props) => {
  return <StyledPaper {...props} />
}

function SelectedCode({ code, description, onDelete, className }) {
  return (
    <div className={clsx("flex w-full fs-unmask", className)}>
      <div className="flex items-center flex-grow bg-white rounded-lg px-3 py-3">
        <Typography
          className="text-sm font-semibold font-mono w-24"
          style={{ lineHeight: "22.5px" }}
        >
          {code}
        </Typography>
        <Typography className="text-base15 leading-5">{description}</Typography>
      </div>
      <div className="bg-white rounded-lg ml-2 flex-shrink-0 flex items-center">
        <IconButton onClick={onDelete} className="p-4">
          <TrashCanIcon className="fill-red-600" width={16} />
        </IconButton>
      </div>
    </div>
  )
}
