import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { ORDERED_TEST_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/patient-orders-types"
import { getPhlebotomistFriendlyName } from "../../utils"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({
  orderedTest,
  order,
  labCompany,
  patient,
}: StatusConfigurationProps) => {
  const collectionCompletedDate = orderedTest.date_sample_collection_completed
  if (!collectionCompletedDate) return null

  const formattedDate = formatDateForHuman(collectionCompletedDate)
  const phlebotomyProviderText =
    order.phlebotomy_providers.length === 1
      ? ` with ${getPhlebotomistFriendlyName(order.phlebotomy_providers[0])}`
      : ""

  const labCompanyShortName = labCompany.short_name

  const nextUpdateElement: React.ReactNode =
    labCompany.notifies_sample_at_lab ? (
      <div>{`${labCompanyShortName}'s next update will be Sample at Lab.`}</div>
    ) : (
      <div>
        {`${labCompanyShortName}'s next update will be Results In.`}

        <InfoTextTooltip disableTouchListener>
          {`${labCompanyShortName} does not update Rupa when they receive a patient's sample.`}
        </InfoTextTooltip>
      </div>
    )

  return (
    <div className="flex flex-col gap-1">
      <p>
        {`${patient.first_name} completed their collection on ${formattedDate}${phlebotomyProviderText}.`}
      </p>
      {nextUpdateElement}
    </div>
  )
}

const LastUpdatedAt = ({ orderedTest }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    orderedTest.date_sample_collection_completed || orderedTest.updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const collectionCompletedStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { orderedTestStatus: ORDERED_TEST_STATUS.COLLECTION_COMPLETED },
    title: "Collection Completed",
    subTitle: SubTitle,
    progressPercentage: PROGRESS_PERCENTAGES.COLLECTION_COMPLETED,
    lastUpdatedAt: LastUpdatedAt,
  }

export default collectionCompletedStatusConfiguration
