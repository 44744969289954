import React, { useCallback, useEffect, useMemo } from "react"

import { FormProvider, useFieldArray } from "react-hook-form"

import { cn } from "@rupahealth/design"

import ControlledSubmitButton from "app/components/forms/ControlledSubmitButton"
import ContentCard from "app/main/results-upload/modals/components/ContentCard"
import FormSectionTitle from "app/main/results-upload/modals/components/FormSectionTitle"
import ResultUploadManualEntryAddBiomarkerResultButton from "app/main/results-upload/modals/manual-upload/ResultsUploadManualEntryAddBiomarkerResultButton"
import ControlledCheckboxInput from "app/patient-portal/fields/components/ControlledCheckboxInput"
import { LabCompany, LabTestType } from "app/types"
import { Biomarker } from "types/biomarker"
import { UserResult } from "types/user-result"

import { UserResultFormBaseSection } from "./UserResultFormBaseSection"
import { UserResultFormBiomarkerSection } from "./UserResultFormBiomarkerSection"
import { useUserResultForm } from "./use-user-result-form"

export interface UserResultFormProps {
  // If there's no patientId, we are in patient portal
  patientId?: string
  // If there's no userResult, we are in create mode
  userResult: UserResult | undefined
  labCompanies: LabCompany[]
  labTestTypes: LabTestType[]
  handleSave: (userResult: UserResult | undefined) => Promise<void>
  onDirtyChange: (dirty: boolean) => void
}

export const UserResultForm: React.FC<UserResultFormProps> = ({
  patientId,
  userResult,
  labCompanies,
  labTestTypes,
  handleSave,
  onDirtyChange,
}) => {
  const { formMethods, readOnly, requireReviewConfirmation, submitForm } =
    useUserResultForm({ patientId, userResult, labCompanies, handleSave })

  useEffect(() => {
    onDirtyChange(formMethods.formState.isDirty)
  }, [formMethods.formState.isDirty, onDirtyChange])

  const {
    fields: resultFields,
    append: appendResult,
    remove: removeResult,
  } = useFieldArray({ control: formMethods.control, name: "results" })
  const sample_type_id = formMethods.watch("sample_type_id")

  const addResult = useCallback(
    (biomarker: Biomarker) => {
      appendResult({
        sample_type_id,
        biomarker_id: biomarker.id,
        value: "",
        units: "",
        normal_min: "",
        normal_max: "",
        user_biomarker_result_id: undefined,
      })
      formMethods.setError(`results.${resultFields.length}`, {})
    },
    [sample_type_id]
  )

  const existingBiomarkerIds = useMemo(() => {
    return resultFields.map((r) => r.biomarker_id)
  }, [resultFields])

  const biomarkersMissingError = useMemo(() => {
    return formMethods.formState.errors.results?.message
  }, [formMethods.formState.errors.results])

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formMethods.handleSubmit(submitForm)()
        }}
        noValidate
      >
        <div className="bg-gray-100 flex flex-col gap-4 p-0">
          <ContentCard>
            <div className="flex flex-col gap-4">
              <FormSectionTitle number={1} title="Lab Information" />
              <UserResultFormBaseSection
                methods={formMethods}
                availableLabCompanies={labCompanies}
                availableLabTestTypes={labTestTypes}
                isReadOnly={readOnly}
              />
            </div>
          </ContentCard>
          <ContentCard>
            <div className="flex flex-col gap-4">
              <div>
                <FormSectionTitle number={2} title="Enter Results" />
                {biomarkersMissingError && (
                  <span className="text-destructive text-xs">
                    {biomarkersMissingError}
                  </span>
                )}
              </div>

              <div
                className={cn("flex flex-col gap-5", {
                  hidden: !resultFields.length,
                })}
              >
                {resultFields.map((field, idx) => (
                  <UserResultFormBiomarkerSection
                    key={field.id ?? `new-${idx}`}
                    field={field}
                    index={idx}
                    onDelete={() => removeResult(idx)}
                    isDeleteLoading={false}
                    existingBiomarkerIds={existingBiomarkerIds}
                    availableLabTestTypes={labTestTypes}
                    methods={formMethods}
                    isReadOnly={readOnly}
                  />
                ))}
              </div>
              <ResultUploadManualEntryAddBiomarkerResultButton
                existingBiomarkerIds={existingBiomarkerIds}
                onBiomarkerSelect={addResult}
              />
            </div>
          </ContentCard>
          {requireReviewConfirmation && (
            <ContentCard>
              <ControlledCheckboxInput
                color="primary"
                name="review_confirmation"
                required
                label={
                  <div className="text-rupa-navy font-open-sans text-sm font-semibold">
                    I've reviewed the extracted information and confirm it's
                    accurate.
                    <span className="text-red-600">*</span>
                  </div>
                }
              />
            </ContentCard>
          )}
          {!readOnly && (
            <ControlledSubmitButton>Save Results</ControlledSubmitButton>
          )}
        </div>
      </form>
    </FormProvider>
  )
}
