import InfoTextTooltip from "app/components/design-system/InfoTextTooltip"
import { KIT_STATUS } from "app/constants.typed"
import formatDateForHuman from "app/utils/format-date-for-human"

import { StatusConfiguration } from "../../types"
import { StatusConfigurationProps } from "../../types/dashboard-types"
import { getPhlebotomistFriendlyName } from "../../utils"
import { PROGRESS_PERCENTAGES } from "../consts"

const SubTitle = ({
  kit,
  order,
  labCompany,
  patient,
}: StatusConfigurationProps) => {
  const collectionCompletedDate =
    kit.attributes.date_sample_collection_completed
  if (!collectionCompletedDate) return null

  const formattedDate = formatDateForHuman(collectionCompletedDate)
  const patientName = patient?.attributes.first_name
  const phlebotomyProviderText =
    order.attributes.phlebotomy_providers.length === 1
      ? ` with ${getPhlebotomistFriendlyName(
          order.attributes.phlebotomy_providers[0]
        )}`
      : ""

  const labCompanyShortName = labCompany?.attributes.short_name

  const nextUpdateElement: React.ReactNode = labCompanyShortName ? (
    labCompany.attributes.notifies_sample_at_lab ? (
      <div>{`${labCompanyShortName}'s next update will be Sample at Lab.`}</div>
    ) : (
      <div>
        {`${labCompanyShortName}'s next update will be Results In.`}

        <InfoTextTooltip disableTouchListener>
          {`${labCompanyShortName} does not update Rupa when they receive a patient's sample.`}
        </InfoTextTooltip>
      </div>
    )
  ) : null

  return (
    <div className="flex flex-col gap-1">
      <p>
        {`${
          patientName ?? "The patient"
        } completed their collection on ${formattedDate}${phlebotomyProviderText}.`}
      </p>
      {nextUpdateElement}
    </div>
  )
}

const LastUpdatedAt = ({ kit }: StatusConfigurationProps) => {
  const lastUpdatedAt =
    kit.attributes.date_sample_collection_completed ||
    kit.attributes.last_updated_at
  return <>{formatDateForHuman(lastUpdatedAt)}</>
}

const collectionCompletedStatusConfiguration: StatusConfiguration<StatusConfigurationProps> =
  {
    when: { kitStatus: KIT_STATUS.COLLECTION_COMPLETED },
    title: "Collection Completed",
    subTitle: SubTitle,
    progressPercentage: PROGRESS_PERCENTAGES.COLLECTION_COMPLETED,
    lastUpdatedAt: LastUpdatedAt,
  }

export default collectionCompletedStatusConfiguration
